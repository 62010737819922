import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  useDisclosure
} from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import { COLORS } from "../../constants/colors";
import { MdAdd } from "react-icons/md";
import { FaUser, FaUserTie } from "react-icons/fa";
import { createItemCheckout, getItemCheckouts } from "./services"; 
import { AuthContext } from "../../context/authContext";
import { getContactList, getGuests } from "../guest-history/service";



const GatePassRecords = () => {

  const handleTypeSelection = (selectedType) => {
    setType(selectedType);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [itemLists, setitemLists] = useState("");
  const [guestId, setGuestId] = useState("");
  const [guestName, setGuestName] = useState("");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const tenantId = userId;

  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const textColor = useColorModeValue(COLORS.black, COLORS.white);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const bgColor = useColorModeValue(COLORS.white, COLORS.green);
  const btTextColor = useColorModeValue(COLORS.white, COLORS.primary);
  const btBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const [guests, setGuests] = useState([]);
  const [contactList, setContactList] = useState([]);





  useEffect(() => {
    getItemCheckouts(tenantId, setRecords, setLoading);
    getGuests(userId, setLoading, setGuests);
    getContactList(userId, setLoading, setContactList);
  }, [tenantId,userId]);


  const handleSubmit = async () => {
    setSubmitting(true);
    const payload =
      type === "errand" ? { type, name, itemLists,tenantId } : { type, guestId, name: guestName, itemLists,tenantId };

    try {
      await createItemCheckout(payload);
      await getItemCheckouts(tenantId, setRecords, setLoading);
      onClose();
      setType("");
      setName("");
      setitemLists("");
      setGuestId("");
      setGuestName("");
    } catch (error) {
      console.error("Error submitting item checkout:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Stack spacing="5">
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>

      <Stack pt="20px" spacing="6">
        <Container>
          <Flex justifyContent="center" alignItems="center" mb="20px">
            <Button
              bg={btBg}
              color={btTextColor}
              size="md"
              leftIcon={<MdAdd size={"2rem"} />}
              onClick={onOpen}
            >
              Add New Record
            </Button>
          </Flex>

          <Stack spacing="4">
            {records.map((record) => (
              <Box
                key={record.id}
                p="1rem"
                borderRadius="10px"
                boxShadow="sm"
                bg={cardBg}
                color={appTextColor}
                _hover={{
                  bg: `${bgColor}`,
                  "& *": {
                    color: TransBg
                  }
                }}
              >
                {/* Flex container for record.name on the left and record.type on the right */}
                <Flex justify="space-between" align="center" mb="2">
                  <Text fontSize="1.1rem" fontWeight="semibold" color="black">
                    {record.name}
                  </Text>
                  <Text>{record.type}</Text>
                </Flex>

                {/* Stack for the remaining details */}
                <Stack fontSize="0.8rem" spacing="1">
                  <Text>Items: {record.itemLists}</Text>
                </Stack>

                <Flex justify="space-between" align="center" mb="2">
                  <Text>Code: {record.code}</Text>
                  <Text>{dayjs(record.createdAt).format("DD MMM, YYYY")}</Text>
                </Flex>
              </Box>
            ))}
          </Stack>
        </Container>
        <Footer />
      </Stack>

       {/* Modal for Adding New Gate Pass */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Gate Pass</ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              <Flex justify="space-around">
                <Box
                  as="button"
                  p="1rem"
                  borderRadius="10px"
                  boxShadow="sm"
                  bg={type === "errand" ? TransBg : cardBg}
                  color={type === "errand" ? "white" : appTextColor}
                  onClick={() => handleTypeSelection("errand")}
                >
                  <Stack align="center" spacing="2">
                    <FaUser fontSize={"3rem"}/>
                    <Text>Errand</Text>
                  </Stack>
                </Box>

                <Box
                  as="button"
                  p="1rem"
                  borderRadius="10px"
                  boxShadow="sm"
                  bg={type === "guest" ? TransBg : cardBg}
                  color={type === "guest" ? "white" : appTextColor}
                  onClick={() => handleTypeSelection("guest")}
                >
                  <Stack align="center" spacing="2">
                    <FaUserTie fontSize={"3rem"}/>
                    <Text>Guest</Text>
                  </Stack>
                </Box>
              </Flex>

              {type === "errand" && (
                <Stack spacing="4">
                  <Input placeholder="Errand Boy's Name" value={name} onChange={(e) => setName(e.target.value)} />
                  <Input placeholder="Items to take out" value={itemLists} onChange={(e) => setitemLists(e.target.value)} />
                </Stack>
              )}

              {type === "guest" && (
                <Stack spacing="4">
<Select
  placeholder="Select Guest"
  value={guestId}
  onChange={(e) => {
    setGuestId(e.target.value);
    setGuestName(e.target.selectedOptions[0].text);
  }}
>
                    {guests.map((guest) => (
                      <option key={guest.id} value={guest._id}>{guest.name}</option>
                    ))}
                  </Select>
                  <Input placeholder="Items to take out" value={itemLists} onChange={(e) => setitemLists(e.target.value)} />
                </Stack>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button bg={btBg} color={btTextColor} onClick={handleSubmit} isDisabled={!type || !itemLists}>Submit</Button>
            <Button variant="ghost" onClick={onClose} ml="3">Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default GatePassRecords;
