import http, { AUTH_ROUTES } from "../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const createItemCheckout = async (data) => {
  try {
    const response = await http.post(AUTH_ROUTES.ITEM_CHECKOUT, data);
    const result = response?.data?.result;

    successNotifier("Item checkout created successfully!");
    return result;
  } catch (error) {
    errorNotifier("Failed to create item checkout. Please try again.");
    console.error("Error creating item checkout:", error);
    throw error;
  }
};

export const getItemCheckouts = async (tenantId, setRecords, setLoading) => {
    try {
      setLoading(true);
    const response = await http.get(AUTH_ROUTES.GET_ITEM_CHECKOUT(tenantId));
    const data = response?.data?.result?.data || [];
    setRecords(data);
    console.info("Tenant ID:", tenantId);
  } catch (error) {
    errorNotifier("Failed to fetch item checkouts.");
    console.error("Error fetching item checkouts:", error);
    throw error;
  } finally {
    setLoading(false);
  }
};
